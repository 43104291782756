.head{
    height: 75px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1f2421;
}

.head p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    color: #BCBCBC;
    margin-right: 40px;
}