.content{
    padding: 0 100px;
    display: flex;
    justify-content: center;
}

.left_part{
    display: flex;
    flex-direction: column;
}

.right_part{
    width: 310px;
    border: 1px solid #373736;
}

.left_column{
    width: 310px;
    border: 1px solid #373736;
}

.left_column_header{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.left_column_header .icons{
    display: flex;
    align-items: center;
}

.left_column_header .icons img{
    margin: 20px;
}

.menu{
    display: flex;
    align-items: center;
    color: white;
}

.menu p{
    margin-right: 40px;
}
.menu img{
    margin-right: 10px;
}

.left_column_content{
    padding: 10px;
}

.middle_column{
    width: 100%;
}