
.table{
    width: 100%;
    border-collapse: collapse;
}

.table tr{
    border-collapse: collapse;
}

.table td{
    border-bottom: 1px solid #DFE6E0;
}

.table thead{
    height: 68px;
    background: #F8F8F8;
    border-bottom: 1px solid #DFE6E0;
}

.trow{
    height: 68px;
    
    transition: ease 0.3s;
}

.trow div{
    display: flex;
    align-items: center;
}

.trow:hover{
    height: 68px;
    border-bottom: 1px solid #DFE6E0;
    background: #F8F8F8;
    transition: ease 0.3s;
}

@media screen and (max-width:1600px) {
    
}