.content{
    display: flex;
    align-items: center;
    border: 1px solid #373736;
    width: 100%;
    height: 82px;
}

.brand{
    border-right: 1px solid #373736;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.brand a{
    color: white;
    margin-left: 8px;
}

.brand h4{
    margin: 5px;
    color: white;
}

.details{
    display: flex;
    align-items: center;
    
}

.look{
    margin: 0px 40px;
}

.look p:first-child{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #CFCFCF;
}

.look p:last-child{
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}