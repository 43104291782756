.nav{
    display: flex;
    align-items:center ;
}

.nav img{
    margin-right: 35px;
}

.loginLink{
    color: #8AB399;
    text-decoration: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-right: 14px;
}

.registerLink{
    width: 99px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    background: linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);
}

.nav li img{
    margin-left: 5px;
    margin-right: 0;
} 
.nav li .usd{
    width: 47px;
    height: 26px;
    background: linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}