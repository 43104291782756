@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 
.style_caption__3lmx7{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 260px;
}

.style_caption__3lmx7 h3{
    margin-bottom: 25px;
}

.style_btn__b6n5E{
    background: linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);
    width: 219px;
    height: 50px;
    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-top: 60px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.style_course__3KOPa{
    background: #F8F8F8;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style_course_item__3dYgI{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 45px;
}

.style_course_item__3dYgI h6{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #000000;
    
}

.style_trend__2f_m1{
    margin: 170px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.style_trend__2f_m1>a{
     
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color:  #8AB399;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-top: 60px;
}

.style_thead__1NQyl{
    background: #F8F8F8;
    padding: 35px 24px;
}
.style_table__2kH35{
    border-collapse: collapse;
    width: calc(100vw - 520px);
}

.style_table__2kH35 th{
     padding: 24.5px;
}
.style_table__2kH35 td{
    padding: 2px;
    border-bottom: 1px solid #DFE6E0;
}

.style_tbg__3926g{
    background: #F7FAF7;
    margin: auto;
    width: 220px;
}
.style_tbg__3926g div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
    height: 100%;
    word-wrap: "break-word";
    white-space: "nowrap"
}

.style_reg_block__NbD1Q{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 354px;
    position: relative;
    background: linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);
}

.style_reg_block__NbD1Q img:first-child{
    position: absolute;
    top: 0;
    left: 0;
}

.style_reg_block__NbD1Q img:last-child{
    position: absolute;
    top: 0;
    right: 0;
}

.style_actions__17_Pw{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.style_actions__17_Pw h4{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
}

.style_actions__17_Pw div button:first-child {
    width: 219px;
    height: 50px;
    background: #FFFFFF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #8AB399;
    border: none;
}

.style_actions__17_Pw div button:first-child a{
    color: #8AB399;
    text-decoration: none;
}

.style_actions__17_Pw div button:last-child {
    width: 219px;
    height: 50px;
    background: #ECE2E2;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #8AB399;
    border: none;
    margin: 18.5px;
}

.style_support__2vQA_{
    display: flex;
    flex-direction: column;
    margin: 170px 0px;
}

.style_support_card__1iriE{
    display: flex;
    justify-content: center;
}

.style_support_card__1iriE div{
    width: 367px;
    height: 189px;
    padding: 40px 57px;
    margin: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160.4%;
    color: #3E4340;
}

.style_support_card__1iriE div img{
    margin-bottom: 20px;
}

.style_support_card__1iriE div:nth-child(2) {
    background: linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}

@media screen and (max-width: 1160px) {
    .style_support_card__1iriE{
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 1100px) {
    .style_caption__3lmx7{
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 60px;
    }
}

@media screen and (max-width: 915px) {
    .style_table__2kH35{
        width: unset;
    }
    .style_course__3KOPa{
        display: grid;
        grid-template-columns: 6fr 6fr;
        justify-items: center;
    }
}

@media screen and (max-width:610px) {
    .style_support__2vQA_>div h3{
        font-size: 2rem;
    }
    .style_btn__b6n5E{
        margin-top: 10px;
        width: 160px;
        height: 40px;
    }
}

@media screen and (max-width: 500px) {
    .style_support_card__1iriE div{
        width: 100%;
    }
}

@media screen and (max-width: 492px) {
    .style_actions__17_Pw div button{
        width: 150px !important;
    }
    .style_reg_block__NbD1Q{
        height: 210px;
    }
    .style_reg_block__NbD1Q img{
        width: 275px;
    }
}


.style_nav__258Fu{
    display: flex;
    align-items:center ;
}

.style_nav__258Fu img{
    margin-right: 35px;
}

.style_loginLink__2-xsn{
    color: #8AB399;
    text-decoration: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-right: 14px;
}

.style_registerLink__2Rm1w{
    width: 99px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    background: linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);
}

.style_nav__258Fu li img{
    margin-left: 5px;
    margin-right: 0;
} 
.style_nav__258Fu li .style_usd__2_EMs{
    width: 47px;
    height: 26px;
    background: linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}
.style_nav__3S1AY{
    display: flex;
    justify-content: space-between;
}
.style_country__hbLQZ{
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
    outline:none;
}

.style_table__7X5nh{
    width: 100%;
    border-collapse: collapse;
}

.style_table__7X5nh tr{
    border-collapse: collapse;
}

.style_table__7X5nh td{
    border-bottom: 1px solid #DFE6E0;
}

.style_table__7X5nh thead{
    height: 68px;
    background: #F8F8F8;
    border-bottom: 1px solid #DFE6E0;
}

.style_trow__CVi-T{
    height: 68px;
    
    transition: ease 0.3s;
}

.style_trow__CVi-T div{
    display: flex;
    align-items: center;
}

.style_trow__CVi-T:hover{
    height: 68px;
    border-bottom: 1px solid #DFE6E0;
    background: #F8F8F8;
    transition: ease 0.3s;
}

@media screen and (max-width:1600px) {
    
}
.App_content__1qPg1{
    padding: 0 100px;
    display: flex;
    justify-content: center;
}

.App_left_part__2HoGT{
    display: flex;
    flex-direction: column;
}

.App_right_part__1-eGp{
    width: 310px;
    border: 1px solid #373736;
}

.App_left_column__2sMV_{
    width: 310px;
    border: 1px solid #373736;
}

.App_left_column_header__2AcWs{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.App_left_column_header__2AcWs .App_icons__2-hlR{
    display: flex;
    align-items: center;
}

.App_left_column_header__2AcWs .App_icons__2-hlR img{
    margin: 20px;
}

.App_menu__1YOA1{
    display: flex;
    align-items: center;
    color: white;
}

.App_menu__1YOA1 p{
    margin-right: 40px;
}
.App_menu__1YOA1 img{
    margin-right: 10px;
}

.App_left_column_content__27J8a{
    padding: 10px;
}

.App_middle_column__39uHf{
    width: 100%;
}
.style_head__2zbx2{
    height: 75px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1f2421;
}

.style_head__2zbx2 p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    color: #BCBCBC;
    margin-right: 40px;
}
.style_content__9nl1e{
    display: flex;
    align-items: center;
    border: 1px solid #373736;
    width: 100%;
    height: 82px;
}

.style_brand__11P1M{
    border-right: 1px solid #373736;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.style_brand__11P1M a{
    color: white;
    margin-left: 8px;
}

.style_brand__11P1M h4{
    margin: 5px;
    color: white;
}

.style_details__-cKi3{
    display: flex;
    align-items: center;
    
}

.style_look__a0xqp{
    margin: 0px 40px;
}

.style_look__a0xqp p:first-child{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #CFCFCF;
}

.style_look__a0xqp p:last-child{
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}
.style_logo__1ImSg{
    width: 200px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 17.5px;
}

.style_icons__36HCq{
    display: flex;
    align-items: center;
}

.style_icons__36HCq div{
    margin: 17.5px;
}

.style_icons__36HCq img{
    width: 30px;
}

.style_cont__3BZOr p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 160.4%;
    color: #FFFFFF;
}

.style_cont__3BZOr li{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding-left: 0;
}
