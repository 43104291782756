.logo{
    width: 200px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 17.5px;
}

.icons{
    display: flex;
    align-items: center;
}

.icons div{
    margin: 17.5px;
}

.icons img{
    width: 30px;
}

.cont p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 160.4%;
    color: #FFFFFF;
}

.cont li{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding-left: 0;
}