.caption{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 260px;
}

.caption h3{
    margin-bottom: 25px;
}

.btn{
    background: linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);
    width: 219px;
    height: 50px;
    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin-top: 60px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course{
    background: #F8F8F8;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.course_item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 45px;
}

.course_item h6{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #000000;
    
}

.trend{
    margin: 170px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.trend>a{
     
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color:  #8AB399;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-top: 60px;
}

.thead{
    background: #F8F8F8;
    padding: 35px 24px;
}
.table{
    border-collapse: collapse;
    width: calc(100vw - 520px);
}

.table th{
     padding: 24.5px;
}
.table td{
    padding: 2px;
    border-bottom: 1px solid #DFE6E0;
}

.tbg{
    background: #F7FAF7;
    margin: auto;
    width: 220px;
}
.tbg div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
    height: 100%;
    word-wrap: "break-word";
    white-space: "nowrap"
}

.reg_block{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 354px;
    position: relative;
    background: linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);
}

.reg_block img:first-child{
    position: absolute;
    top: 0;
    left: 0;
}

.reg_block img:last-child{
    position: absolute;
    top: 0;
    right: 0;
}

.actions{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.actions h4{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #FFFFFF;
}

.actions div button:first-child {
    width: 219px;
    height: 50px;
    background: #FFFFFF;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #8AB399;
    border: none;
}

.actions div button:first-child a{
    color: #8AB399;
    text-decoration: none;
}

.actions div button:last-child {
    width: 219px;
    height: 50px;
    background: #ECE2E2;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #8AB399;
    border: none;
    margin: 18.5px;
}

.support{
    display: flex;
    flex-direction: column;
    margin: 170px 0px;
}

.support_card{
    display: flex;
    justify-content: center;
}

.support_card div{
    width: 367px;
    height: 189px;
    padding: 40px 57px;
    margin: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160.4%;
    color: #3E4340;
}

.support_card div img{
    margin-bottom: 20px;
}

.support_card div:nth-child(2) {
    background: linear-gradient(104.61deg, #8AB399 8.29%, #6AA182 53.15%, #6AA182 98.01%);
    border-radius: 4px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}

@media screen and (max-width: 1160px) {
    .support_card{
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 1100px) {
    .caption{
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 60px;
    }
}

@media screen and (max-width: 915px) {
    .table{
        width: unset;
    }
    .course{
        display: grid;
        grid-template-columns: 6fr 6fr;
        justify-items: center;
    }
}

@media screen and (max-width:610px) {
    .support>div h3{
        font-size: 2rem;
    }
    .btn{
        margin-top: 10px;
        width: 160px;
        height: 40px;
    }
}

@media screen and (max-width: 500px) {
    .support_card div{
        width: 100%;
    }
}

@media screen and (max-width: 492px) {
    .actions div button{
        width: 150px !important;
    }
    .reg_block{
        height: 210px;
    }
    .reg_block img{
        width: 275px;
    }
}

